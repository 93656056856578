import { useState, useRef, useEffect } from 'react';
import { WizardConfig, WizardInputUploadQuestion } from '../WizardConfig';
import { Stack, Typography } from '@mui/material';
import { WizardPagination } from '../WizardPagination';
import { useForm } from 'react-hook-form-mui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Wizard } from '../Wizard';
import { useHistory } from 'react-router';
import {
  TextFieldWithUpload,
  TextFieldWithUploadProps,
} from 'components/ProspectList/AutoCompleteWithUpload';
import { optionsSchema, type Option } from 'hooks/Prospects/useIcpFilter';
import { capitalizeFirstChar } from 'utils/util';
import { endpoints } from 'variables/endpoint-urls';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';
import { V2 } from '../../../redux/reducers/api/prospects';
import {
  jobTitles,
  organizationSizeRanges,
} from 'components/ProspectList/ICPBuilder/presets';
import {
  fieldConfigurations,
  vendeluxEmployeeFieldConfigurations,
} from 'components/ProspectList/ICPBuilder/CsvUploadModal';
import { autocompletePlaceholder } from '../wizardUtils';
import { optionsApi } from 'redux/reducers/api/options';
import { z } from 'zod';
import { useUserState } from 'hooks/common/useUserState';
import { crmApi } from 'redux/reducers/api/crm';

export interface AutocompleteWithUploadQuestionProps<
  TWizard = Wizard<WizardConfig>
> {
  question: WizardInputUploadQuestion;
  onSubmit?: (response: any) => void;
  initialValue?: any;
  onItemSelected?: (values: any[]) => void;
  wizard: TWizard;
}

export function AutocompleteWithUploadQuestion({
  question,
  onSubmit,
  initialValue,
  onItemSelected,
  wizard,
}: AutocompleteWithUploadQuestionProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultValues: Record<string, any> = {};
  const { team } = useUserState();

  const { data: getCrmFilterOptions, isFetching: fetchingCrmFilters } =
    crmApi.useGetCrmFilterOptionsQuery(team.uuid);

  const { data: prospectLists, isFetching: fetchingProspectLists } =
    optionsApi.useGetProspectListOptionsQuery();

  defaultValues[question.name] = initialValue;

  const schema = z.object({
    [question.name]: optionsSchema.min(1, {
      message: 'Please add at least one item.',
    }),
  });

  type Schema = z.infer<typeof schema>;

  // Initialize form with default values from localStorage.
  const form = useForm<Schema>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [value, setValue] = useState<Option[]>(initialValue || []);
  const label = capitalizeFirstChar(question.subType);

  // Keep react-hook-form state in sync with changes coming from the autocomplete.
  useEffect(() => {
    if (value) {
      form.setValue(question.name, value, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [form, question?.name, value]);

  function submitForm() {
    try {
      if (onSubmit) onSubmit(value);
    } catch (e) {
      // Localstorage can't handle this much data; show a nice message.
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        dispatch(
          setSnacksQueue({
            message:
              'Your upload is too large for the wizard to handle. Try again with fewer rows.',
          })
        );
      }
    }
  }

  function onNextClick() {
    formRef.current?.requestSubmit();
  }

  function onPreviousClick() {
    history.goBack();
  }

  let endpointFunction: TextFieldWithUploadProps['endpointFunction'];
  let filterFetchedOptions: TextFieldWithUploadProps['filterFetchedOptions'];
  let fetch = true;
  let options: Option[] = [];
  let allowUpload = true;

  // If we've set allowUpload in the question, that value overrides the default.
  if (typeof question.allowUpload !== 'undefined') {
    allowUpload = question.allowUpload;
  }

  // TODO: abstract this out when we add another wizard, if needed. These may or may not be list-wizard specific.
  switch (question.subType) {
    case 'organizations':
      endpointFunction = endpoints.prospects.get.v2_autocomplete_organizations;
      filterFetchedOptions = (
        data: V2.GetAutocompleteOrganizationsResponsePayload
      ) => {
        return data.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        }));
      };
      break;
    case 'people':
      endpointFunction = endpoints.prospects.get.v2_autocomplete_profiles;
      filterFetchedOptions = (
        data: V2.GetAutocompleteProfilesResponsePayload
      ) => {
        return data.map(({ name, uuid, organization__name }) => ({
          label: `${name} (${organization__name})`,
          value: uuid,
        }));
      };
      break;
    case 'job-titles-keywords':
      fetch = false;
      options = jobTitles.map((title) => ({
        label: title,
        value: title,
        disabled: false,
      }));
      break;
    case 'prospect-lists':
      fetch = fetchingProspectLists;
      options = (prospectLists || []).map((list) => ({
        label: list.label,
        value: list.uuid,
        disabled: false,
      }));
      break;

    case 'organization-size':
      fetch = false;
      options = organizationSizeRanges.map((size) => ({
        label: size,
        value: size,
      }));
      allowUpload = false;
      break;

    case 'crm-owner':
      fetch = fetchingCrmFilters;
      options = getCrmFilterOptions?.success
        ? getCrmFilterOptions.results.grouped_crm_filters.OWNER.map((item) => ({
            label: item.label,
            value: item.label, // DEV-3683
          }))
        : [];
      allowUpload = false;
      break;

    case 'crm-deal-owner':
      fetch = fetchingCrmFilters;
      options = getCrmFilterOptions?.success
        ? getCrmFilterOptions.results.grouped_crm_filters.OWNER.map((item) => ({
            label: item.label,
            value: item.label, // DEV-3683
          }))
        : [];
      allowUpload = false;
      break;

    case 'crm-deal-stage':
      fetch = fetchingCrmFilters;
      options = getCrmFilterOptions?.success
        ? getCrmFilterOptions.results.grouped_crm_filters.DEAL_STAGE.map(
            (item) => ({
              label: item.label,
              value: item.label, // DEV-3683
            })
          )
        : [];
      allowUpload = false;
      break;

    default:
      throw new Error(`Unknown question subType: ${question.subType}`);
  }

  // the label and description can be a function that takes the wizard as an argument
  const questionLabel =
    typeof question.label === 'function'
      ? question.label(wizard)
      : question.label;
  const description =
    typeof question.description === 'function'
      ? question.description(wizard)
      : question.description;

  const { isVendeluxEmployee } = useUserState();

  return (
    <form onSubmit={form.handleSubmit(submitForm)} ref={formRef}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Typography fontWeight={500} variant="h6">
            {questionLabel}
          </Typography>
          {question.description ? (
            <Typography variant="body2">{description}</Typography>
          ) : null}

          <TextFieldWithUpload
            label={label}
            placeholder={
              question.placeholder ??
              (question.subType
                ? autocompletePlaceholder(question.subType)
                : 'Enter data to search for here')
            }
            options={options}
            allowUpload={allowUpload}
            fetch={fetch}
            isLink={false}
            onValueChanged={(newValue) => {
              setValue(() => newValue);
            }}
            value={value}
            onRemoveItemClicked={(option) => {
              setValue(value.filter((v) => v.value !== option.value));
            }}
            endpointFunction={endpointFunction}
            filterFetchedOptions={filterFetchedOptions}
            uploadModalFields={
              isVendeluxEmployee
                ? vendeluxEmployeeFieldConfigurations[question.subType]
                : fieldConfigurations[question.subType]
            }
            uploadModalSeparator={
              ['organizations', 'people'].includes(question.subType)
                ? 'Optional:'
                : 'AND'
            }
            textInputProps={{
              // Adds unique data attribute to text input for behavioral tracking (e.g., Pendo).
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
              name: question.name,
            }}
            fileInputProps={{
              // Adds unique data attribute to button
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
            }}
            uploadButtonProps={{
              // Adds unique data attribute to button
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
            }}
          />

          {form.formState.errors[question.name] ? (
            <Typography
              sx={{
                color: 'red',
              }}
            >
              {form.formState.errors[question.name]?.message}
            </Typography>
          ) : null}
        </Stack>
        <WizardPagination
          wizard={wizard}
          question={question}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Stack>
    </form>
  );
}
